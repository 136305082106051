// extracted by mini-css-extract-plugin
export var backLink = "order-login-module--back-link--e819a";
export var box = "order-login-module--box--883a6";
export var button = "order-login-module--button--68f37";
export var center = "order-login-module--center--53d48";
export var container = "order-login-module--container--56d5d";
export var grid = "order-login-module--grid--fa101";
export var guest = "order-login-module--guest--4e5d7";
export var login = "order-login-module--login--7dd71";
export var loginForm = "order-login-module--login-form--d1fa0";
export var register = "order-login-module--register--82963";
export var text = "order-login-module--text--ee35f";
export var textLink = "order-login-module--text-link--8a4c0";
export var title = "order-login-module--title--683d4";